<template>
  <div class="menu_bar" :class="isShow?'right_position_normal':''">
    <div class="menu_bar_title">
      <div class="left_small_box">
        <img src="../../assets/imgs/app/app_menu_arc@2x.png" alt />
      </div>
      <div class="right_small_box" @click="handleClose">
        <img src="../../assets/imgs/app/app_menu_close@2x.png" alt />
      </div>
    </div>
    <ul class="menu_bar_content">
      <li @click="goRouter('/')">
        <img src="../../assets/imgs/app/home_icons@2x.png" alt />
        <span>Home</span>
      </li>
      <li @click="goRouter('/Products')">
        <img src="../../assets/imgs/app/app_menu_icon1.png" alt />
        <span>Products</span>
      </li>
      <li @click="goRouter('/features')">
        <img src="../../assets/imgs/app/app_menu_icon2.png" alt />
        <span>Features</span>
      </li>
      <li @click="goRouter('/Pricing')">
        <img src="../../assets/imgs/app/app_menu_icon2.png" alt />
        <span>Pricing</span>
      </li>
      <li @click="goRouter('/Blogs')">
        <img src="../../assets/imgs/app/app_menu_icon3.png" alt />
        <span>Blogs</span>
      </li>
      <li @click="handleBookBtn">
        <img src="../../assets/imgs/app/app_menu_icon4.png" alt />
        <span>Book A Demo</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userId: 1,
      name: "",
      email: "",
      isShowInner: false
    };
  },
  created() {
    // this.userId = 'arcweb' + new Date().getTime() + this.MathRand();

  },
  mounted() {
    // this.$intercom.boot({
    //   user_id: this.userId,
    //   name: this.name,
    //   email: this.email
    // });
  },
  watch: {
    email(email) {
      // this.$intercom.update({ email });
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    handleBookBtn() {
      // this.$intercom.show();
    },
    MathRand() {
      var Num = "";
      for (var i = 0; i < 6; i++) {
        Num += Math.floor(Math.random() * 10);
      }
      return Num
    },
    handleClose() {
      // this.isShow=false
      this.$emit("changeIsShow");
    }
  },
  props: ["isShow"]
};
</script>
<style lang="scss" scoped>
.menu_bar {
  position: fixed;
  right: -295px;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  height: 100%;
  width: 287px;
  padding: 54px 24px 0 33px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.7);
  -moz-transition: top 1.3s; /* Firefox 4 */
  -webkit-transition: top 0.3s; /* Safari and Chrome */
  -o-transition: top 0.3s; /* Opera */
  .menu_bar_title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left_small_box {
      width: 66px;
      height: 33px;
      img {
        width: 100%;
      }
    }
    .right_small_box {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
      }
    }
  }
  .menu_bar_content {
    li {
      text-align: left;
      width: 100%;
      height: 24px;
      margin: 24px 0;
      line-height: 24px;
      display: flex;
      justify-content: flex-start;
      img {
        height: 100%;
        margin-right: 15px;
      }
      span {
        // display: inline-block;
        height: 24px;
        line-height: 24px;
        font-family: Poppins !important;
        font-size: 16px;
        color: #000;
      }
    }
  }
}
.right_position_normal {
  right: 0;
}
</style>